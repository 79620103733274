import { useState, useEffect } from 'react';
import { Container, CircularProgress, makeStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './theme/theme';
import AppList from './AppList';
import './App.css';
import { AppCategory } from './app-model';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.between('xs', 'sm')]: {
      width: "100%",
    },
    [theme.breakpoints.up('md')]: {
      width: "50%",
    },
  }
}));

const App = () => {
  const classes = useStyles();
  const [list, setList] = useState<AppCategory[]>([]);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        'https://pinwheel-app-list.s3.us-east-2.amazonaws.com/public/app-list.json',
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const data = await response.json();
      setList(data);
      setLoad(false);
    }
    fetchData();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Container className={classes.root}>
        {load ? <CircularProgress color='secondary' /> : list && <AppList list={list} />}
      </Container>
    </ThemeProvider>
  );
};

export default App;
