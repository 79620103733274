import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import AppAccordian from './components/accordian/Accordian';
import { AppCategory } from './app-model';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.between('xs', 'sm')]: {
      width: `calc(100% + 8px)`,
      margin: `-4px`,
    },
    [theme.breakpoints.up('md')]: {
      width: `calc(100% + 24px)`,
      margin: `-12px`,
    },
  },
  item: {
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '.5px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '12px',
    },
  },
}));

interface Props {
  list: AppCategory[];
}

const AppList = ({ list }: Props) => {
  const classes = useStyles();
  list.sort((cat1, cat2) => {
    return cat1.name.localeCompare(cat2.name);
  })

  return (
    <Box paddingTop={5} paddingBottom={5}>
      <Grid container className={classes.root}>
        <Grid item xs={12} md={12} className={classes.item}>
          {list.map((item) => (
            <AppAccordian items={item} key={item.id} />
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};
export default AppList;
