import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  typography: {
    fontFamily: ['Poppins'],
    h1: {
      fontSize: '72px',
      lineHeight: '96px',
      letterSpacing: '0px',
      fontStyle: 'Semi-bold',
    },
    h2: {
      fontSize: '51px',
      lineHeight: '72px',
      letterSpacing: '0px',
      fontStyle: 'semi-bold',
    },
    h3: {
      fontSize: '36px',
      lineHeight: '48px',
      letterSpacing: '0px',
      fontStyle: 'regular',
    },
    h4: {
      fontSize: '25px',
      lineHeight: '32px',
      letterSpacing: '0px',
      fontStyle: 'regular',
    },
    h5: {
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: '0px',
      fontStyle: 'semi-bold',
    },
    h6: {
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '16%',
      fontStyle: 'semi-bold',
    },
    // paragraph
    body1: {
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: '-0.5%',
      fontStyle: 'regular',
    },
    // muted text
    body2: {
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '-0.5%',
      fontStyle: 'medium',
    },
    caption: {
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: '-0.5%',
      fontStyle: 'medium-italic',
    },
    // breadcrumbs
    subtitle1: {
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: '-0.5%',
      fontStyle: 'medium',
    },
    // micro
    subtitle2: {
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '4%',
      fontStyle: 'medium',
    },
  },
  palette: {
    primary: {
      main: '#100052',
    },
    secondary: {
      main: '#f8c923',
    },
    // background: {
    //   default: 'rgba(243,247,251,1.0)'
    // }
  },
});
export default theme;
