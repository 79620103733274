import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
require('dotenv').config();
const rootel = document.getElementById('root');

function render() {
  const root = createRoot(rootel);
  root.render(<App />);
}

if (module.hot) {
  module.hot.accept('./App', function () {
    setTimeout(render);
  });
}
render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
