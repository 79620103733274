import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grid,
  Box,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import { AppCategory } from '../../app-model';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.between('xs', 'sm')]: {
      '&:last-child': {
        boxShadow: '0px 1px 3px 0px rgb(0 0 0 / 12%)',
      },
    },
  },
  listContainer: {
    justifyContent: 'flex-start',
    [theme.breakpoints.up('xs')]: {
      paddingLeft: theme.spacing(1),
      gap: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      gap: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(2),
      gap: theme.spacing(2),
    },
  },
  listItem: {
    alignItems: 'flex-start',
    alignContent: 'flex-start',
  },
  itemContainer: {
    width: '70px',
  },
  itemImage: {
    height: '70px',
    width: '70px',
  },
  itemText: {
    height: '32px',
    whiteSpace: 'normal',
    overflow: 'hidden',
    textJustify: 'inter-word',
  },
}));

interface Props {
  items: AppCategory;
}

export default function AppAccordian({ items }: Props) {
  const classes = useStyles();
  return (
    <Accordion className={classes.root}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant='h5'>{items.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container className={classes.listContainer} wrap='wrap'>
          {items.apps.map((app) => (
            <Grid item key={app.id}>
              <Box className={classes.itemContainer}>
                <img src={app.image} alt={app.name} className={classes.itemImage} />
                <Typography variant='subtitle2' align='center' className={classes.itemText}>
                  {app.name}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

// xs={4} md={3} lg={2}
